import React, { useContext, useEffect, useState } from "react";
import slider1 from "../assets/image/slider1.jpg";
import slider2 from "../assets/image/slider2.jpg";
import slider3 from "../assets/image/slider3.jpg";
import slider4 from "../assets/image/slider4.jpg";
import slider5 from "../assets/image/slider5.jpg";
import slider6 from "../assets/image/slider6.jpg";
import slider8 from "../assets/image/slider8.jpg";
import slider9 from "../assets/image/slider9.jpg";
import slider10 from "../assets/image/slider10.jpg";
import logo from "../assets/image/xor-logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { NavLink } from "react-router-dom";
import { addItem } from "../others/addtocart";
import axios from "axios";
import slugify from "react-slugify";
import { Toaster, toast } from "sonner";
import translations from "../data/langData";
import { LanguageContext } from "../context/LangaugeContext";

import xorvideo from "../assets/image/xorvideo.mp4";

const HomeSlider = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [selectedData, setSelectedData] = useState();
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const [activeIndex, setActiveIndex] = useState(0); // Aktif slaytı takip et

  const handleToggle = (item) => {
    setSelectedData(item);
    setToggle(!toggle);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const increaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  useEffect(() => {
    axios
      .get("https://myshops.az/apk/app_prod.php?q=xor")
      .then((res) => {
        const data = res.data;
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [sentencesHtml, setSentencesHtml] = useState("");

  useEffect(() => {
    if (selectedData) {
      const description =
        language == "en"
          ? selectedData.description_ru
          : selectedData.description_az;
      const sentences = description
        .split(/(?<=\.)/)
        .filter((sentence) => sentence.trim() !== "");
      const numberOfSentencesToShow = 1;
      const selectedSentences = sentences.slice(0, numberOfSentencesToShow);
      const sentencesHtml = selectedSentences.join(" ");
      setSentencesHtml(sentencesHtml);
    }
  }, [selectedData, language]);

  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={true}
        modules={[EffectFade, Navigation]}
        className="mySwiper"
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        <SwiperSlide>
          <div className="slider_container">
            <video
              src={xorvideo}
              autoPlay
              loop
              muted
              className="video"
              style={{ display: activeIndex === 0 ? 'block' : 'none' }} 
            ></video>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider_container">
            <div className="slider_text">
              <h1>
                <img src={logo} alt="" className="img-fluid" />
              </h1>
              <p>Titanium X2 Ebony</p>
              <button className="readMore">
                <NavLink to="/collections">{t.showMore}</NavLink>
              </button>
            </div>

            <img src={slider1} />
            <div className="outline"></div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider_container">
            <div className="slider_text">
              <h1>
                <img src={logo} alt="" className="img-fluid" />
              </h1>
              <p>XOR Titanium X2 Classic</p>
              <button className="readMore">
                <NavLink to="/collections">{t.showMore}</NavLink>
              </button>
            </div>

            <img src={slider2} />
            <div className="outline"></div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="container-fluid my-4">
        <Swiper
          slidesPerView={1}
          spaceBetween={3}
          pagination={{
            clickable: true,
          }}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 3,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {[slider3, slider5, slider6, slider9, slider4, slider10, slider8].map(
            (item, index) => (
              <SwiperSlide key={index}>
                <img
                  src={item}
                  alt={`Slide ${index + 1}`}
                  className="img-fluid"
                  style={{
                    width: "100%",
                    height: "370px",
                    objectFit: "cover",
                  }}
                />
              </SwiperSlide>
            )
          )}
        </Swiper>
      </div>

      <div className="our-team-area pt-5">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h2>{t.collections}</h2>
              <p>{t.luxury}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container collections_slider"
        style={{ paddingBottom: "60px" }}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={3}
          navigation={true}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 4,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 3,
            },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          <Toaster position="top-right" />
          <div id="grid-view" class="tab-pane active">
            <div class="shop-product-area">
              <div class="row">
                {data.map((item) => (
                  <SwiperSlide>
                    <div class="col-lg-12 col-md-4 col-sm-6 mt-30">
                      <div class="single-product-wrap">
                        <div class="product-image">
                          <NavLink
                            to={`/${slugify(
                              `${item.name_show}-${item.memory + "GB"}-${
                                item.value
                              }`
                            ).toLowerCase()}`}
                            onClick={() => window.scrollTo({ top: 0 })}
                          >
                            <img
                              src={item.icon}
                              alt="Xor image"
                              className="img-fluid"
                            />
                          </NavLink>
                          <div class="quick_view">
                            <a
                              href="#"
                              title="quick view"
                              class="quick-view-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalCenter"
                              onClick={() => handleToggle(item)}
                            >
                              <i class="fa fa-search"></i>
                            </a>
                          </div>
                        </div>
                        <div class="product-content">
                          <h3>
                            <NavLink
                              to={`/${slugify(
                                `${item.name_show}-${item.memory + "GB"}-${
                                  item.value
                                }`
                              ).toLowerCase()}`}
                              onClick={() => window.scrollTo({ top: 0 })}
                            >
                              {item.name_show.split(" (")[0]} {item.value}
                            </NavLink>
                          </h3>
                          <div class="price-box">
                            <span class="new-price">{item.price} ₼</span>
                          </div>
                          <div class="product-action">
                            <button
                              class="add-to-cart"
                              title="Add to cart"
                              onClick={() => {
                                addItem(item);
                                toast.success(`${item.name_show}`, {
                                  className: "py-3 px-2",
                                  duration: 600,
                                });
                              }}
                            >
                              <i class="fa fa-plus"></i>
                              {t.added}
                            </button>
                            <div class="star_content">
                              <ul class="d-flex">
                                <li>
                                  <a class="star" href="#">
                                    <i class="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a class="star" href="#">
                                    <i class="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a class="star" href="#">
                                    <i class="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a class="star" href="#">
                                    <i class="fa fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a class="star" href="#">
                                    <i class="fa fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
            </div>
          </div>
        </Swiper>
      </div>
      <div
        className={`modal fade modal-wrapper ${toggle ? "show" : ""}`}
        id="exampleModalCenter"
        style={{ display: `${toggle ? "block" : ""}`, padding: "0" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="modal-inner-area row">
                <div className="col-lg-5 col-md-6 col-sm-6">
                  <div className="product-details-left">
                    <div className="product-details-images slider-navigation-1">
                      <div className="lg-image">
                        <img
                          src={selectedData && selectedData.icon}
                          alt="product image"
                        />
                      </div>
                    </div>
                    {/* <div className="product-details-thumbs slider-thumbs-1">										
                <div className="sm-image"><img src="assets/images/product/1.jpg" alt="product image thumb" /></div>
              </div> */}
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 col-sm-6">
                  <div className="product-details-view-content">
                    <div className="product-info">
                      <h3>
                        {selectedData && selectedData.name_show.split(" (")[0]}{" "}
                        {selectedData && selectedData.value}{" "}
                      </h3>
                      <div className="price-box">
                        {/* <span className="old-price">$70.00</span> */}
                        <span className="new-price">
                          {selectedData && selectedData.price} ₼
                        </span>
                        <span className="old-price">
                          {selectedData && selectedData.taksit} ₼
                        </span>
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: sentencesHtml }} />
                      <div className="product-variants">
                        <div className="produt-variants-color">
                          <label>{t.color}</label>
                          <ul className="color-list">
                            <li>
                              <a
                                href="#"
                                className="orange-color active"
                                style={{
                                  background: `#${selectedData &&
                                    selectedData.color_code}`,
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="single-add-to-cart">
                        <div className="cart-quantity">
                          <div className="quantity">
                            <label>{t.quantity}</label>
                            <div className="cart-plus-minus">
                              <input
                                className="cart-plus-minus-box"
                                value={quantity}
                                type="text"
                              />
                              <div
                                className="dec qtybutton"
                                onClick={increaseQuantity}
                              >
                                <i className="fa fa-angle-down" />
                              </div>
                              <div
                                className="inc qtybutton"
                                onClick={decreaseQuantity}
                              >
                                <i className="fa fa-angle-up" />
                              </div>
                            </div>
                          </div>
                          <Toaster position="top-right" />
                          <button
                            className="add-to-cart"
                            onClick={() => {
                              addItem(selectedData, quantity);
                              toast.success(`${selectedData.name_show}`, {
                                className: "py-3 px-2",
                                duration: 600,
                              });
                            }}
                            type="submit"
                          >
                            {t.added}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSlider;
